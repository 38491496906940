exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agences-js": () => import("./../../../src/pages/agences.js" /* webpackChunkName: "component---src-pages-agences-js" */),
  "component---src-pages-freelances-js": () => import("./../../../src/pages/freelances.js" /* webpackChunkName: "component---src-pages-freelances-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merci-js": () => import("./../../../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */)
}

